<template>
	<div class="not-found flex-center flex-column px-7">
		<h1 class="font-weight-light w-100 text-center mb-5">
			ERROR
		</h1>
		<img src="@/assets/img/404.png" alt="Page not found" style="max-width: 100%;">
		<h1 class="font-weight-light w-100 text-center mt-5">
			STRANICA NIJE PRONAĐENA
		</h1>
		<router-link to="/" class="mt-2">Početna</router-link>
	</div>
</template>
<style lang="scss" scoped>
.not-found {
	min-height: 80vh;
	background-color: #eee;
}
</style>
<script>
export default {
	name: 'NotFound'
}
</script>